export const getFormNameFromId = (id: string) => {
  switch (id) {
    case "2FTU02UDRK1VC7M":
      return "Headache";

    case "OTOGCJ7U4EH8Z27":
      return "Fever";

    case "DKFCGRQB7SMW6U1":
      return "Runny Nose";

    case "HGTIGRGK050FB5X":
      return "Sore Throat";

    case "s41ejtxsqkmu4xc":
      return "Cough";

    case "7AIRI1CBTEUE91Q":
      return "Chest Pain";

    case "HC97LDPJ07CM1H3":
      return "Sneezing";

    case "U1JN84IQZWC03NS":
      return "Itchy Eyes";

    case "02P9Z27UCEQUH3N":
      return "Itchy Skin";

    case "CXDQD6G5Q2OPSNT":
      return "Eye Discharge";

    case "V7KUANBEAVP7BXO":
      return "Eye Pain";

    case "ZEC9H7LPXNPK5NW":
      return "Ear Pain";

    case "3AZ9QPS0X1EV7KI":
      return "Ear Discharge";

    case "4TN1K1T2FTYHRRO":
      return "Upper back/neck pain";

    case "NK2F88HNONFMG7P":
      return "Lower back pain";

    case "BLWE014H1CH6VEA":
      return "Painful Urination";

    case "9IWSP5CQ2C2I5H4":
      return "Bloody urination";

    case "HGWVP1LKYRTK2SQ":
      return "Unusual Vaginal Discharge";

    case "J8OIY73OR3T19M0":
      return "Unusual Vaginal Bleeding";

    case "WT4R9ZJZZHBN77W":
      return "Genital Sores & Ulcers";

    case "N7FQKCTA36CYLSC":
      return "Irregular Menstrual Cycle";

    case "PBMN63H9JKWOYW1":
      return "Unusual Penile Discharge";

    case "ASE0TRP2CCIVD92":
      return "Painful Urination (Men)";

    case "C3NZUS8T91CEE3U":
      return "Scrotal Pain";

    case "BAPUC3S53EPUFWG":
      return "Premature Ejaculation";

    case "NMR5VV0PGR1EQL1":
      return "Weak Erection";

    case "EXUM2AVQ6GE55TW":
      return "Heartburn";

    case "SI9H4JDGW2W8B76":
      return "Lower Abdominal Pain";

    case "PF2Y18S0ZRJ7J34":
      return "Upper Abdominal Pain";

    case "46ILM042ONAO7NP":
      return "Vomiting";

    case "LU9D956VOSLFRBP":
      return "Diarrhoea";

    case "56HEHP9WIYOKEMU":
      return "Abdominal Swelling";

    case "BH5M6ERSQFOGKVU":
      return "Abdominal (Stomach) Pain";

    case "V0WS2GU9APQ64K6":
      return "Anal Protrusion";

    case "2TMW0466GVE360B":
      return "Diabetes (Care Support)";

    case "HEP9Q6RXHT9ETJS":
      return "Hypertension";

    default:
      return "General Consultation";
  }
};
