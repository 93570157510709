import styled from "styled-components";

export const ConsultsTableWrapper = styled.div`
  .input-pane {
    padding: 10px 10px;
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);

    input {
      height: 45px;
      border-radius: 6px;
    }
  }
`;

export const ConsultsItemWrapper = styled.div`
  padding: 20px;
  border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);

  &:last-child {
    border-bottom: none;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  p {
    margin-top: 8px;
    font-size: 13px;
    margin-bottom: 0px;
  }

  i {
    font-size: 13px;
    font-style: italic;
    margin-bottom: 0px;
  }
`;

export const ConsultsTableHeader = styled.div`
  height: auto;
  display: flex;
  align-items: center;

  ul {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0px 40px;

    li {
      display: block;
      float: left;
      padding: 20px 10px 10px 10px;
      margin-right: 44px;
      cursor: pointer;
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      text-align: left;
      color: #002e46;

      &.active {
        color: #0f9af0;
        border-bottom: 3px solid #0f9af0;
      }
    }
  }
`;

export const ConsultsTableBody = styled.div`
  border-radius: 8px;
  border-radius: 8px;

  .ant-dropdown-link.pending {
    text-transform: capitalize;
    color: #ff9359;
  }

  .ant-dropdown-link.dispatched {
    text-transform: capitalize;
    color: #59ca7f;
  }

  .ant-dropdown-link.processing {
    text-transform: capitalize;
    color: #6db8d6;
  }

  .ant-card {
    box-shadow: none !important;

    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }

  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }

  .ant-table-wrapper {
    border-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table {
    border-radius: 8px;

    .odd-row td.ant-table-cell {
      background: #0f9af01a;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }

    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 16px;
      border-bottom: 0.5px solid #1890ff;
    }

    tr {
      th:first-child {
        padding: 10px 20px;
      }
    }

    tr {
      td {
        padding: 8px 16px;
      }
    }

    tr {
      .table-action-span {
        cursor: pointer;
        color: #08b;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
    }

    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;

      .flex {
        display: flex;

        img {
          margin-right: 10px;
          border: 0.8px solid #0f9af0;
          border-radius: 50%;
          height: 100%;
          padding: 2px;
        }
      }

      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }

      .action {
        img {
          margin-left: 10px;
        }
      }

      p {
        margin-bottom: 5px;
      }

      span.subtext {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0f9af0;
        opacity: 0.75;
      }
    }
  }
`;
