import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: left;

  .header {
    margin-top: 20px;

    h1 {
      margin-bottom: 4px;
    }
  }

  .content {
    margin-top: 16px;

    p {
      span:first-of-type {
        font-weight: 500;
      }
    }

    ol {
      margin: 0;
      padding-left: 20px;

      li {
        margin-bottom: 10px;
      }
    }

    .ant-tabs-nav {
      max-width: max-content;
      margin: 0 0 16px;
    }

    .ant-tabs-tab-btn {
      font-size: 14px;
    }
  }
`;
