import styled from "styled-components";

interface CardProps {
  marginBottom: number;
  padding: number;
  hasBoxShadow?: boolean;
}

export const CardWrapper = styled.div<CardProps>`
  .ant-card {
    width: 100%;
    border-radius: 8px;
    background: #fff;

    ${(props) => !props.hasBoxShadow && `border: none`};

    box-shadow: ${(props) =>
      props.hasBoxShadow ? "1px 3px 3px rgba(0, 120, 226, 0.1)" : "none"};

    ${(props) => `margin-bottom: ${props.marginBottom}px`};
    .ant-card-body {
      ${(props) => `padding: ${props.padding}px`};
    }
  }
`;
