import axios, { AxiosInstance } from "axios";

const httpInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_SECRET_KEY as string}`
  }
});

httpInstance.defaults.headers.post["Content-Type"] = "application/json";

export default httpInstance;

export const httpPublicInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_KEY as string}`
  }
});

httpPublicInstance.defaults.headers.post["Content-Type"] = "application/json";
