import { Routes, Route } from "react-router-dom";

import "antd/dist/antd.css";
import "App.css";

import ConsultsTable from "components/ConsultsTable";
import SingleConsult from "pages/SingleConsult";

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<ConsultsTable />} />

        <Route path='/:consultId' element={<SingleConsult />} />
      </Routes>
    </div>
  );
}

export default App;
