import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Tabs, message as AntdMessage } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { format, differenceInYears } from "date-fns";

import { httpPublicInstance } from "utils/https";
import { getFormNameFromId } from "utils/helpers";
import {
  PatientDetails,
  SingleConsult as SingleConsultType
} from "utils/types";
import InfoTypography from "components/InfoTypography";

import { Wrapper } from "./styles";

function SingleConsult() {
  const navigate = useNavigate();
  const { consultId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedConsult, setSelectedConsult] = useState<SingleConsultType>();
  const [patientInfo, setPatientInfo] = useState<PatientDetails>();

  // fetch consult details
  useEffect(() => {
    let patientID: string;

    async function getData() {
      setIsLoading(true);
      try {
        const res = await httpPublicInstance.get(`/consults/${consultId}`);
        const resData: SingleConsultType = res.data.data;
        patientID = resData.user.id;
        setSelectedConsult(resData);

        // fetch patient details as well
        const res2 = await httpPublicInstance.get(`/users/${patientID}`);
        const patientData: PatientDetails = res2.data.data;
        setPatientInfo(patientData);
      } catch (err) {
        AntdMessage.error("Unable to get consult details. Please try again!");
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [consultId]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  function downloadPrescriptions() {
    const element = document.createElement("a");
    const file = new Blob(
      [
        `\nAppointment date - ${format(
          new Date(selectedConsult?.created_at!),
          "MMMM d, yyyy h:mma"
        )}
        \nDoctor - ${selectedConsult?.provider.first_name} ${
          selectedConsult?.provider.last_name
        } (${selectedConsult?.provider.email})

        \nPatient - ${selectedConsult?.user.first_name} ${
          selectedConsult?.user.last_name
        } (${differenceInYears(new Date(), new Date(patientInfo?.dob!))} years)

        ${selectedConsult?.medications
          ?.map(
            (medication, index) =>
              `\n${index + 1}. ${medication.dosage} ${medication.name}, ${
                medication.signature
              } for ${medication.number_of_days} days.`
          )
          .join(" ")}`
      ],
      {
        type: "text/plain;charset=utf-8"
      }
    );

    element.href = URL.createObjectURL(file);
    element.download = `${selectedConsult?.user.first_name}'s-prescriptions.txt`;
    document.body.appendChild(element);
    element.click();
  }

  const { TabPane } = Tabs;

  return selectedConsult && patientInfo ? (
    <Wrapper>
      <Button onClick={() => navigate("/")}>
        <ArrowLeftOutlined /> Back to list
      </Button>

      <div className='header'>
        <h1>
          {selectedConsult.user.first_name} {selectedConsult.user.last_name}
        </h1>
        {selectedConsult.diagnosis?.length ? (
          <p>
            {selectedConsult.diagnosis.map((diagnosis, index, arr) => (
              <span key={diagnosis.id}>
                {diagnosis.condition}
                {index === arr.length - 1 ? "" : ", "}
              </span>
            ))}
          </p>
        ) : (
          <p>{getFormNameFromId(selectedConsult.form)}</p>
        )}
      </div>

      <div className='content'>
        <Tabs>
          <TabPane tab='Consult details' key='1'>
            <p>
              <span>Created at: </span>{" "}
              {format(
                new Date(selectedConsult.created_at),
                "MMMM d, yyyy h:mma"
              )}
            </p>

            <p>
              <span>Encounter reason: </span>{" "}
              {getFormNameFromId(selectedConsult.form)}
            </p>

            {selectedConsult.symptoms?.length ? (
              <p>
                <span>Symptoms: </span>{" "}
                {selectedConsult.symptoms.map((symptom, index, arr) => (
                  <span key={symptom.id}>
                    {symptom.symptom} for {symptom.number_of_days} days
                    {index === arr.length - 1 ? "." : ", "}
                  </span>
                ))}
              </p>
            ) : null}

            {selectedConsult.diagnosis?.length ? (
              <p>
                <span>Diagnosis: </span>{" "}
                {selectedConsult.diagnosis.map((diagnosis, index, arr) => (
                  <span key={diagnosis.id}>
                    {diagnosis.condition}
                    {index === arr.length - 1 ? "." : ", "}
                  </span>
                ))}
              </p>
            ) : null}

            <p>
              <span>Provider name: </span>{" "}
              {`${selectedConsult.provider.first_name} ${selectedConsult.provider.last_name}`}
            </p>

            <p>
              <span>Provider email: </span>{" "}
              {`${selectedConsult.provider.email} `}
            </p>
          </TabPane>

          <TabPane tab='Patient details' key='2'>
            <p>
              <span>Name: </span>{" "}
              {`${patientInfo.first_name} ${patientInfo.last_name}`}
            </p>
            <p>
              <span>Email: </span> {`${patientInfo.email}`}
            </p>
            <p>
              <span>Phone: </span> {`${patientInfo.phone}`}
            </p>
            <p>
              <span>Date of birth: </span>{" "}
              {`${format(new Date(patientInfo.dob), "MMMM d, yyyy")}`}
            </p>

            <h4 style={{ marginTop: "18px", fontSize: "18px" }}>
              Medical Info
            </h4>
            <InfoTypography
              label='Weight'
              value={
                patientInfo.medical_record.weight
                  ? patientInfo.medical_record.weight + "kg"
                  : ""
              }
            />
            <InfoTypography
              label='Height'
              value={
                patientInfo.medical_record.height
                  ? patientInfo.medical_record.height + "ft"
                  : ""
              }
            />
            <InfoTypography
              label='BMI'
              value={
                //  weight (kg) / [height (m)]2
                patientInfo.medical_record.height &&
                patientInfo.medical_record.weight
                  ? (
                      +patientInfo.medical_record.weight /
                      +Math.pow(+patientInfo.medical_record.height / 3.281, 2)
                    ).toFixed(2)
                  : ""
              }
            />
            <InfoTypography
              label='Blood Group'
              value={patientInfo.medical_record.blood_group || ""}
            />
            <InfoTypography
              label='Genotype'
              value={patientInfo.medical_record.genotype || ""}
            />
            <InfoTypography
              label='Allergies'
              value={
                patientInfo.medical_record.allergies?.length
                  ? patientInfo.medical_record.allergies
                  : ""
              }
            />
            <InfoTypography
              label='Medications'
              value={
                patientInfo.medical_record.medications.length
                  ? patientInfo.medical_record.medications
                  : ""
              }
            />
            <InfoTypography
              label='Vaccinations'
              value={
                patientInfo.medical_record.vaccinations.length
                  ? patientInfo.medical_record.vaccinations.map(
                      (item: any) => `${item.type} - ${item.period}`
                    )
                  : ""
              }
            />
            <InfoTypography
              label='Surgeries'
              value={
                patientInfo.medical_record.surgeries.length
                  ? patientInfo.medical_record.surgeries.map(
                      (item: any) => `${item.type} - ${item.period}`
                    )
                  : ""
              }
            />
            <InfoTypography
              label='Transfusion History'
              value={
                patientInfo.medical_record.transfusion_history.length
                  ? patientInfo.medical_record.transfusion_history.map(
                      (item: any) => `${item.type} - ${item.period}`
                    )
                  : ""
              }
            />
            <InfoTypography
              label='Family History'
              value={
                patientInfo.medical_record.family_medical_history.length
                  ? patientInfo.medical_record.family_medical_history.map(
                      (item: any) =>
                        `${Object.keys(item)[0]} in ${
                          (Object.values(item)[0] as any)?.join(", ") || ""
                        }`
                    )
                  : ""
              }
            />
            <InfoTypography
              label='Smoking Habit'
              value={patientInfo.medical_record.smoking_habit || ""}
            />
            <InfoTypography
              label='Drinking Habit'
              value={patientInfo.medical_record.drinking_habit || ""}
            />
          </TabPane>

          <TabPane tab='Prescriptions' key='3'>
            <ol>
              {selectedConsult.medications?.length ? (
                selectedConsult.medications?.map((medication) => (
                  <li
                    key={medication.id}
                  >{`${medication.dosage}  ${medication.name}, ${medication.signature} for ${medication.number_of_days} days.`}</li>
                ))
              ) : (
                <p>No prescriptions for this consult</p>
              )}
            </ol>

            {selectedConsult.medications?.length ? (
              <Button
                onClick={downloadPrescriptions}
                style={{ marginTop: "20px" }}
              >
                Download
              </Button>
            ) : null}
          </TabPane>

          <TabPane tab='Investigations' key='4'>
            <ol>
              {selectedConsult.investigations?.length ? (
                selectedConsult.investigations?.map((investigation) => (
                  <li
                    key={investigation.id}
                  >{` ${investigation.name}, (${investigation.category})`}</li>
                ))
              ) : (
                <p>No investigations for this consult</p>
              )}
            </ol>
          </TabPane>

          <TabPane tab='Recommendations' key='5'>
            <ol>
              {selectedConsult.recommendations?.length ? (
                selectedConsult.recommendations?.map(
                  (recommendation, index) => (
                    <li key={index}>{recommendation}</li>
                  )
                )
              ) : (
                <p>No recommendation for this consult</p>
              )}
            </ol>
          </TabPane>
        </Tabs>
      </div>
    </Wrapper>
  ) : null;
}

export default SingleConsult;
