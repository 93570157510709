import { useEffect, useState } from "react";
import { Table, message as AntdMessage } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import httpInstance from "utils/https";
import { SingleConsult } from "utils/types";
import { getFormNameFromId } from "utils/helpers";
import Card from "../Card";
import { ConsultsTableBody, ConsultsTableWrapper } from "./styles";

function ConsultsTable() {
  const navigate = useNavigate();

  const [consults, setConsults] = useState<SingleConsult[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const res = await httpInstance.get(`/consults`);
        const resData: SingleConsult[] = res.data.data;

        setConsults(resData.filter((consult) => consult.status === "ended"));
      } catch (err) {
        console.log("error dey", err);
        AntdMessage.error("Unable to load data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  const columns = [
    {
      title: "Patient",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      render: (_: string, record: SingleConsult) => {
        const { first_name, last_name } = record.user || {};
        return (
          <div>
            <p
              style={{
                margin: 0,
                maxWidth: "max-content",
                minWidth: "120px",
                lineHeight: "1.7",
                textTransform: "capitalize"
              }}
            >
              {first_name} {last_name}
            </p>
          </div>
        );
      }
    },
    {
      title: "Doctor",
      dataIndex: "provider_name",
      key: "provider_name",
      width: 200,
      render: (_: string, record: SingleConsult) => {
        const { first_name, last_name } = record.provider;
        return (
          <div>
            <p
              style={{
                margin: 0,
                maxWidth: "max-content",
                minWidth: "120px",
                lineHeight: "1.7",
                textTransform: "capitalize"
              }}
            >
              {first_name} {last_name}
            </p>
          </div>
        );
      }
    },
    {
      title: "Reason for encounter",
      dataIndex: "form",
      key: "form",
      render: (_: any, record: SingleConsult) => {
        return (
          <p style={{ width: "max-content" }}>
            {getFormNameFromId(record.form)}
          </p>
        );
      }
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (_: any, record: SingleConsult) => {
        return (
          <>
            <p
              style={{
                maxWidth: "max-content",
                minWidth: "100px",
                lineHeight: "1.7"
              }}
            >
              {format(new Date(record.created_at), "MMMM d, yyyy h:mma")}
            </p>
          </>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: SingleConsult) => {
        return (
          <span
            className='table-action-span'
            onClick={() => navigate(`/${record.id}`)}
          >
            <EyeOutlined /> View details
          </span>
        );
      }
    }
  ];

  return (
    <>
      <h1>Completed Consults </h1>
      <Card>
        <ConsultsTableWrapper>
          <ConsultsTableBody>
            <Table
              dataSource={consults}
              columns={columns}
              style={{ pointerEvents: isLoading ? "none" : "all" }}
              scroll={
                !isLoading && consults && consults?.length > 0
                  ? { x: 700 }
                  : window.matchMedia("(max-width: 650px)").matches
                  ? { x: 700 }
                  : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true
              }}
            />
          </ConsultsTableBody>
        </ConsultsTableWrapper>
      </Card>
    </>
  );
}

export default ConsultsTable;
