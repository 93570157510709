import styled from "styled-components";
import { ElementType, ReactNode } from "react";

export type TypographyProps = {
  component?: ElementType;
  variant?: keyof typeof variantMapping;
  color?:
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error"
    | "warning"
    | "info"
    | "success"
    | "inherit";
  align?: "left" | "center" | "right" | "justify";
  transform?: "capitalize" | "lowercase" | "uppercase";
  display?: "block" | "inline";
  weight?: "bold" | "bolder" | "normal" | "lighter" | "inherit";
  noWrap?: boolean;
  gutterBottom?: boolean;
  className?: string;
  children?: ReactNode;
} & React.ComponentPropsWithoutRef<"p">;

const Typography = styled(function Typography(props: TypographyProps) {
  const { variant, component, ...rest } = props;

  const Component = component || variantMapping[variant!];

  return <Component {...rest} />;
}).attrs(({ className, variant, component, children }) => ({
  variant,
  className,
  component,
  children
}))(({ display, gutterBottom, color, noWrap, align, transform, weight }) => ({
  display,
  margin: 0,
  ...(gutterBottom ? { marginBottom: "0.35em" } : {}),

  color,
  ...(noWrap
    ? {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    : {}),
  textAlign: align,
  textTransform: transform,
  fontWeight: weight
}));

Typography.defaultProps = {
  variant: "body1",
  // color: 'inherit'
  color: "textPrimary"
  // align: 'left'
};

export default Typography;

const variantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  caption: "span",
  button: "span",
  overline: "span"
};
